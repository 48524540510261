export const asset_lang_vi = {
  quydautu: 'Quỹ đầu tư',
  chuongtrinhdautu: 'Chương trình đầu tư',
  // fund
  tonggiatrithitruong: 'Giá trị thị trường',
  chitiet: 'Chi tiết',
  loilo: 'Lời/Lỗ',
  soluongdonviquy: 'Số lượng đơn vị quỹ',
  giaccqgannhat: 'Giá CCQ gần nhất',
  lailochuathuchien: 'Lãi/Lỗ chưa thực hiện',
  mua: 'Mua',
  ban: 'Bán',
  chuyendoi: 'Chuyển đổi',
  // scheme
  tongsoluongdonviquy: "Tổng số lượng đơn vị quỹ",
  soccqduocquyenban: "Số CCQ được quyền bán",
  canhandonggop: "Cá nhân đóng góp",
  phucloidanhan: "Phúc lợi đã nhận",
  soccqdangbihancheban: "Số CCQ đang bị hạn chế bán",
  canhandonggopbihancheban: "Cá nhân đóng góp bị hạn chế bán",
  phucloichuanhan: "Phúc lợi chưa nhận",
  ccq: "CCQ"
};

export const asset_lang_en = {
  quydautu: 'By Funds',
  chuongtrinhdautu: 'By Scheme',
  // fund
  tonggiatrithitruong: 'Market value',
  chitiet: 'Detail',
  loilo: 'Gain/loss',
  soluongdonviquy: 'Units',
  giaccqgannhat: 'Nav',
  lailochuathuchien: 'Unrealized Gain/loss',
  mua: 'Subcribe',
  ban: 'Redeem',
  chuyendoi: 'Switch',
  // scheme
  tongsoluongdonviquy: "Total Units",
  soccqduocquyenban: "Redeemable units",
  canhandonggop: "Personal Savings",
  phucloidanhan: "Vested units",
  soccqdangbihancheban: "Unredeemable units",
  canhandonggopbihancheban: "Blocked-up Savings",
  phucloichuanhan: "Vested units",
  ccq: "units"

}