import { Button, Div, Label } from "components";
import { Ecolors } from "constants/themes";
import React, { useState } from "react";
import { useAppSelector } from "store/hooks";
import { convertNumber, convertPercent } from "utils/utils";
import Details_Button from "./details_button";
import Row_button_create_order from "./row_button_create_order";
import Row_space from "./row_space";
import Table_units from "./table_units";

const Item_List_By_Scheme = (p: { data: any }) => {
  const [is_expand, set_is_expand] = useState<boolean>(true);
  const I18nState = useAppSelector((state) => state.languages.I18nState);

  const {
    programName,
    marketValue,
    unrealizedGainOrLossPercent,
    totalUnit,
    unrealizedGainOrLossAmount,
    nav,
    color,
    redeemableUnit,
    unredeemableUnit,
    isLTS,
    redeemableUnitDetail,
    unredeemableUnitDetail,
    productId,
    programId,
    programNameEn,
  } = p.data;
  return (
    <Button
      onPress={() => {
        // set_is_expand((a) => !a);
      }}
      width={339}
      marginBottom={16}
      paddingHorizontal={16}
      paddingVertical={19}
      borderColor={Ecolors.bordercolorBox}
      borderWidth={1}
      borderRadius={8}
    >
      <Div
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Div flexDirection="row" alignItems="center" justifyContent="center">
          <Div
            widthHeight={12}
            borderRadius={12}
            backgroundColor={color}
            marginRight={9}
          />
          <Label size={15} multilanguage={false}>
            {I18nState == "vi" ? programName : programNameEn}
          </Label>
        </Div>
        <Details_Button productId={productId} programId={programId} />
      </Div>
      <Row_space
        left={{
          title: "new_asset.tonggiatrithitruong",
        }}
        right={{
          title: "new_asset.lailochuathuchien",
        }}
      />
      <Row_space
        left={{
          title: convertNumber(Math.round(marketValue)),
          multilanguage: false,
          color: Ecolors.textColor,
          size: 16,
        }}
        right={{
          title: `(${convertPercent(unrealizedGainOrLossPercent)})`,
          multilanguage: false,
          color:
            unrealizedGainOrLossPercent >= 0
              ? Ecolors.greenColor
              : Ecolors.redColor,
          size: 16,
        }}
      />

      <Div
        height={1}
        marginTop={10}
        backgroundColor={Ecolors.bordercolorBox}
        width={307}
      />
      <Row_space
        left={{
          title: "new_asset.giaccqgannhat",
        }}
        right={{
          title: convertNumber(nav),
          multilanguage: false,
          color: Ecolors.textColor,
        }}
      />
      <Row_space
        left={{
          title: "new_asset.tongsoluongdonviquy",
        }}
        right={{
          title: convertNumber(totalUnit, true),
          multilanguage: false,
          color: Ecolors.textColor,
        }}
      />
      {is_expand && isLTS && (
        <>
          <Div
            height={1}
            marginTop={10}
            backgroundColor={Ecolors.bordercolorBox}
            width={307}
          />
          <Row_space
            left={{
              title: "new_asset.soccqduocquyenban",
              color: Ecolors.textColor,
            }}
            right={{
              title: convertNumber(redeemableUnit, true),
              multilanguage: false,
              color: Ecolors.greenColor,
            }}
          />
          <Table_units
            left={{
              title: "new_asset.canhandonggop",
              amount: redeemableUnitDetail.personalSavings,
            }}
            right={{
              title: "new_asset.phucloidanhan",
              amount: redeemableUnitDetail.vestedUnits,
            }}
          />
          <Row_space
            left={{
              title: "new_asset.soccqdangbihancheban",
              color: Ecolors.textColor,
            }}
            right={{
              title: convertNumber(unredeemableUnit, true),
              multilanguage: false,
              color: Ecolors.redColor,
            }}
          />
          <Table_units
            left={{
              title: "new_asset.canhandonggopbihancheban",
              amount: unredeemableUnitDetail.blockedUpSavings,
            }}
            right={{
              title: "new_asset.phucloichuanhan",
              amount: unredeemableUnitDetail.unvestedUnit,
            }}
          />
        </>
      )}
      <Row_space
        left={{
          title: "new_asset.lailochuathuchien",
        }}
        right={{
          title: convertNumber(Math.round(unrealizedGainOrLossAmount)),
          multilanguage: false,
          color:
            unrealizedGainOrLossAmount >= 0
              ? Ecolors.greenColor
              : Ecolors.redColor,
        }}
      />
      <Row_button_create_order productId={productId} programId={programId} />
    </Button>
  );
};

export default React.memo(Item_List_By_Scheme);
