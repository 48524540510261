import { Button, ImageView, Label } from "components";
import { Icons } from "constants/Icons";
import { Ecolors } from "constants/themes";
import React from "react";
import { navigate } from "services/navigation";
import { useDataCreateOrder } from "./asset_hook";

const Details_Button = (p: {
  data?: any;
  onPress?: () => void;
  productId?: any;
  programId?: any;
}) => {
  const { product, scheme } = useDataCreateOrder(p.productId, p.programId);
  return (
    <Button
      onPress={() => {
        // if (scheme) {
        //   navigate("ProgramDetailsModal", {
        //     data: scheme,
        //   });
        //   return;
        // }
        navigate("ListAssetDetailsModal", {
          product,
          scheme,
        });
        return;
      }}
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      width={77}
      height={23}
      backgroundColor={Ecolors.spaceColor}
      borderRadius={23}
    >
      <Label marginRight={2} size={12} fontWeight="bold">
        {"new_asset.chitiet"}
      </Label>
      <ImageView widthHeight={10} resizeMode="contain" source={Icons.details} />
    </Button>
  );
};

export default React.memo(Details_Button);
