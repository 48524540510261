import { Div } from "components";
import React from "react";
import { apiAsset } from "services/apiAsset";
import { useQuery } from "./asset_hook";
import Item_list_by_fund from "./item_list_by_fund";

const List_By_Fund = () => {
  const { data } = useQuery(() => apiAsset.getAssetByFund());
  return (
    <Div flex={1} alignItems="center" paddingTop={16}>
      {data?.map((item: any, index: number) => {
        return <Item_list_by_fund data={item} key={index} />;
      })}
    </Div>
  );
};

export default React.memo(List_By_Fund);
