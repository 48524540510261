import { useEffect, useState } from 'react';
import { getProductList } from 'reducer/asset';
import { apiInvestment } from 'services/apiInvestment';
import { useAppSelector } from 'store/hooks';

export const useQuery = (promise, dependensies = []) => {
  const [data, setData] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const fetchData = async (query?: any) => {
    setLoading(true);
    try {
      const res = await promise(query);
      if (res.status == 200 && !!res.data) {
        setData(res.data);
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, dependensies);

  return {
    data,
    error,
    loading,
    setData,
    refetch: fetch,
  };
};

export const useDataCreateOrder = (productid, schemeid) => {
  const [product, setProduct] = useState<any>(null);
  const [scheme, setScheme] = useState<any>(null);
  const listProduct = useAppSelector(state => getProductList(state));

  useEffect(() => {
    if (!!productid && !!listProduct) {
      bindDataProduct(productid, listProduct);
    }
    if (!!product && !!schemeid) {
      bindDataScheme(schemeid, product);
    }
    return () => { };
  }, [productid, listProduct, product]);

  const bindDataScheme = async (id, objectdata) => {
    const listScheme = await apiInvestment.investmentLoadScheme({
      productId: productid,
    });
    const res = listScheme.data?.find(a => a.id == id);
    if (!!res) {
      setScheme(res);
    }
  };
  const bindDataProduct = (id, list) => {
    const res = list.find(a => a.id == id);
    if (!!res) {
      setProduct(res);
    }
  };
  return { product, scheme };
};
