import { Button, Div, HeaderBack, ImageView, Label, NonData } from "components";
import { Ecolors } from "constants/themes";
import React, { useEffect } from "react";
import { RefreshControl, ScrollView } from "react-native";
import { useDispatch } from "react-redux";
import { getAsset, loadAsset } from "reducer/asset";
// import { getAsset, loadAsset } from "reducer/asset";
import { useRoute } from "@react-navigation/native";
import { Icons } from "constants/Icons";
import { navigate } from "services/navigation";
import { useAppSelector } from "store/hooks";
import { convertNumber } from "utils/utils";
import PercentAsset from "./PercentAsset";

function AssetScreen() {
  const dispatch = useDispatch();
  const asset = useAppSelector((state) => getAsset(state));
  const loadingAsset = useAppSelector((state) => state.asset.loading);

  const route = useRoute<any>();

  useEffect(() => {
    // onRefresh();
    return () => {};
  }, []);

  const onRefresh = () => {
    dispatch(loadAsset({}));
  };

  return (
    <Div screen={true} backgroundColor={Ecolors.whiteColor}>
      <HeaderBack type={3} flowApp={route.params?.flowApp || ""} />
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={false}
            onRefresh={onRefresh}
            tintColor={Ecolors.mainColor}
          />
        }
      >
        {!loadingAsset && !asset.productList?.length ? (
          <>
            <Div
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
              paddingHorizontal={15}
            >
              <Button
                paddingTop={15}
                flexDirection="row"
                alignItems="center"
                onPress={() => {
                  navigate("PendingOrderScreen");
                }}
              >
                <Label
                  fontWeight="bold"
                  color={Ecolors.linkColor}
                >{`overviewscreen.xemlenhchokhop`}</Label>
                <ImageView
                  source={Icons.forward}
                  marginTop={3}
                  widthHeight={12}
                  tintColor={Ecolors.linkColor}
                  marginLeft={5}
                  resizeMode="contain"
                />
              </Button>
            </Div>
            <NonData />
          </>
        ) : (
          <>
            <Div height={"100%"}>
              <Div
                width={"100%"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Label
                  size={14}
                  marginTop={14}
                >{`overviewscreen.tonggiatrithitruong`}</Label>
                <Label
                  size={28}
                  marginTop={2}
                  fontWeight={"700"}
                  color={Ecolors.mainColor}
                  multilanguage={false}
                >
                  {convertNumber(
                    `${asset?.sumOfValueCurrently || ""}`?.split(".")?.[0] ?? ""
                  ) || ""}
                </Label>
              </Div>
              <PercentAsset />
            </Div>
          </>
        )}
      </ScrollView>
    </Div>
  );
}

export default React.memo(AssetScreen);
