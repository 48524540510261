import { Button, Div, Label } from "components";
import { Ecolors } from "constants/themes";
import React, { useState } from "react";
import { convertAmount, convertNumber, convertPercent } from "utils/utils";
import Details_Button from "./details_button";
import Row_button_create_order from "./row_button_create_order";
import Row_space from "./row_space";

const Item_List_By_Fund = (p: { data: any }) => {
  const [is_expand, set_is_expand] = useState<boolean>(true);
  const {
    productName,
    marketValue,
    unrealizedGainOrLossPercent,
    totalUnit,
    unrealizedGainOrLossAmount,
    color,
    nav,
    productId,
  } = p.data;

  return (
    <Button
      onPress={() => {
        // set_is_expand((a) => !a);
      }}
      width={339}
      marginBottom={16}
      paddingHorizontal={16}
      paddingVertical={19}
      borderColor={Ecolors.bordercolorBox}
      borderWidth={1}
      borderRadius={8}
    >
      <Div
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Div flexDirection="row" alignItems="center" justifyContent="center">
          <Div
            widthHeight={12}
            borderRadius={12}
            backgroundColor={color}
            marginRight={9}
          />
          <Label size={15} multilanguage={false}>
            {productName}
          </Label>
        </Div>
        <Details_Button productId={productId} />
      </Div>
      <Row_space
        left={{
          title: "new_asset.tonggiatrithitruong",
        }}
        right={{
          title: "new_asset.lailochuathuchien",
        }}
      />
      <Row_space
        left={{
          title: convertNumber(Math.round(marketValue)),
          multilanguage: false,
          color: Ecolors.textColor,
          size: 16,
        }}
        right={{
          title: `(${convertPercent(unrealizedGainOrLossPercent)})`,
          multilanguage: false,
          color:
            unrealizedGainOrLossPercent >= 0
              ? Ecolors.greenColor
              : Ecolors.redColor,
          size: 16,
        }}
      />
      {is_expand && (
        <>
          <Div
            height={1}
            marginTop={10}
            backgroundColor={Ecolors.bordercolorBox}
            width={307}
          />
          <Row_space
            left={{
              title: "new_asset.soluongdonviquy",
            }}
            right={{
              title: convertNumber(totalUnit, true),
              multilanguage: false,
              color: Ecolors.textColor,
            }}
          />
          <Row_space
            left={{
              title: "new_asset.giaccqgannhat",
            }}
            right={{
              title: convertNumber(nav),
              multilanguage: false,
              color: Ecolors.textColor,
            }}
          />
          <Row_space
            left={{
              title: "new_asset.lailochuathuchien",
            }}
            right={{
              title: convertAmount(Math.round(unrealizedGainOrLossAmount)),
              multilanguage: false,
              color:
                unrealizedGainOrLossAmount >= 0
                  ? Ecolors.greenColor
                  : Ecolors.redColor,
            }}
          />
        </>
      )}
      <Row_button_create_order productId={productId} />
    </Button>
  );
};

export default React.memo(Item_List_By_Fund);
