import { Button, Div, Label } from "components";
import { Ecolors } from "constants/themes";
import React, { useState } from "react";
import { Log, widthScreen } from "utils";
import List_by_fund from "./list_by_fund";
import List_by_scheme from "./list_by_scheme";

const Row = (p: {
  title: string;
  pressKey: string;
  onPress: (t: string) => void;
  isFocus: boolean;
}) => {
  return (
    <Button
      onPress={() => {
        Log("onpress", p.pressKey);
        p.onPress(p.pressKey);
      }}
      style={{
        width: widthScreen / 2,
      }}
      borderBottomWidth={2}
      borderBottomColor={p.isFocus ? Ecolors.mainColor : Ecolors.grayColor}
      height={48}
      alignItems="center"
      justifyContent="center"
    >
      <Label
        textAlign="center"
        fontWeight="bold"
        color={p.isFocus ? Ecolors.mainColor : Ecolors.grayColor}
        size={14}
      >
        {p.title}
      </Label>
    </Button>
  );
};

const renderTabFocus = (p: { tabFocus: string }) => {
  switch (p.tabFocus) {
    case "List_by_fund":
      return <List_by_fund />;
    case "List_by_scheme":
      return <List_by_scheme />;
    default:
      return <Div />;
  }
};

const Tab_Assset = () => {
  const [tabFocus, setTabFocus] = useState<string>("List_by_fund");

  return (
    <Div paddingTop={7}>
      <Div
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Row
          title="new_asset.quydautu"
          pressKey="List_by_fund"
          isFocus={tabFocus == "List_by_fund"}
          onPress={(t: string) => setTabFocus(t)}
        />
        <Row
          isFocus={tabFocus == "List_by_scheme"}
          title="new_asset.chuongtrinhdautu"
          pressKey="List_by_scheme"
          onPress={(t: string) => setTabFocus(t)}
        />
      </Div>
      {renderTabFocus({ tabFocus })}
      {/* <TabViewComp
        onChange={e => {}}
        multilanguage={true}
        data={[
          {
            component: (a: any) => <List_by_fund {...a} />,
            key: 'new_asset.quydautu',
            title: 'new_asset.quydautu',
          },
          {
            component: (a: any) => <List_by_scheme {...a} />,
            key: 'new_asset.chuongtrinhdautu',
            title: 'new_asset.chuongtrinhdautu',
          },
        ]}
      /> */}
    </Div>
  );
};

export default React.memo(Tab_Assset);
