import { Alert, Button, Div, ImageView, Label } from "components";
import { Icons } from "constants/Icons";
import { Ecolors } from "constants/themes";
import React from "react";
import { apiAsset } from "services/apiAsset";
import { navigate } from "services/navigation";
import { useAppSelector } from "store/hooks";
import { useDataCreateOrder } from "./asset_hook";

const Row_Button_Create_Order = (p: {
  productId?: any;
  programId?: any;
  is_hide_buy?: boolean;
}) => {
  // const listProduct = useAppSelector(state => getListProduct(state));
  const I18nState = useAppSelector((state) => state.languages.I18nState);
  const currentUser = useAppSelector<any>((state) => state.authen.currentUser);
  const { product, scheme } = useDataCreateOrder(p.productId, p.programId);
  const fatca =
    currentUser.fatca?.fatca1 == false &&
    currentUser.fatca?.fatca2 == false &&
    currentUser.fatca?.fatca3 == false
      ? true
      : false;

  const onBuy = async () => {
    try {
      const ses = await apiAsset.checkStatusSurvey();
      if (ses.status == 200) {
        navigate("CreateOrderModal", {
          orderType: "BUY",
          initData: {
            product: product,
            scheme: scheme,
          },
        });
        return;
      }
      Alert.showError({ content: ses.message });
    } catch (error: any) {
      Alert.showError({ content: error });
    }
  };

  const onSell = async () => {
    if (!currentUser?.investmentProfile?.isReceivedHardProfile) {
      const content =
        I18nState == "vi"
          ? `Tài khoản của quý khách hiện tại chưa được duyệt hoặc chưa nhận được hồ sơ gốc/chưa ký hợp đồng điện tử. Nên không thể thực hiện lệnh bán/ chuyển đổi.`
          : `You cannot create redemption/switching transaction due to pending account approval or not received hardcopy Open Account Contract/ unsigned e-Contract`;
      if (
        currentUser?.investmentProfile?.status?.code !=
          `INVESTMENT_PROFILE_APPROVE` ||
        (currentUser?.investmentProfile?.status?.code ==
          "INVESTMENT_PROFILE_APPROVE" &&
          !currentUser?.investmentProfile?.isReceivedHardProfile)
      ) {
        if (fatca == true) {
          Alert.show({
            content: content,
            multilanguage: false,
            type: 2,
            titleClose: "alert.dongy",
            onCancel: () => {},
            onConfirm: () => {
              navigate("DigitalSignatureScreen");
            },
          });
        } else {
          Alert.showError({
            content: content,
            multilanguage: false,
          });
        }
        return;
      }
    }
    navigate("CreateOrderModal", {
      orderType: "SELL",
      initData: {
        product: product,
        scheme: scheme,
      },
    });
  };

  const onTransfer = async () => {
    if (!currentUser?.investmentProfile?.isReceivedHardProfile) {
      const content =
        I18nState == "vi"
          ? `Tài khoản của quý khách hiện tại chưa được duyệt hoặc chưa nhận được hồ sơ gốc/chưa ký hợp đồng điện tử. Nên không thể thực hiện lệnh bán/chuyển đổi.`
          : `You cannot create redemption/switching transaction due to pending account approval or not received hardcopy Open Account Contract/ unsigned e-Contract`;
      if (
        currentUser?.investmentProfile?.status?.code !=
          `INVESTMENT_PROFILE_APPROVE` ||
        (currentUser?.investmentProfile?.status?.code ==
          "INVESTMENT_PROFILE_APPROVE" &&
          !currentUser?.investmentProfile?.isReceivedHardProfile)
      ) {
        if (fatca == true) {
          Alert.show({
            content: content,
            multilanguage: false,
            type: 2,
            titleClose: "alert.dongy",
            onCancel: () => {},
            onConfirm: () => {
              navigate("DigitalSignatureScreen");
            },
          });
        } else {
          Alert.showError({
            content: content,
            multilanguage: false,
          });
        }
        return;
      }
    } else {
      navigate("CreateOrderModal", {
        orderType: "TRANSFER",
        initData: {
          product: product,
          scheme: scheme,
        },
      });
    }
  };

  return (
    <Div
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      marginTop={15}
    >
      {!p.is_hide_buy && (
        <Button
          onPress={() => onBuy()}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          backgroundColor={Ecolors.mainColor}
          borderRadius={7}
          paddingVertical={5}
          width={I18nState == "vi" ? 83 : 103}
        >
          <ImageView
            widthHeight={27}
            resizeMode={"contain"}
            source={Icons.buy}
          />
          <Label size={14} color={Ecolors.whiteColor}>
            {`createordermodal.mua`}
          </Label>
        </Button>
      )}
      <Button
        onPress={() => onSell()}
        flexDirection={"row"}
        alignItems={"center"}
        backgroundColor={Ecolors.mainColor}
        paddingLeft={0}
        paddingRight={10}
        justifyContent={"center"}
        borderRadius={7}
        paddingVertical={5}
        width={I18nState == "vi" ? 84 : 95}
      >
        <ImageView
          marginHorizontal={9}
          widthHeight={27}
          resizeMode={"contain"}
          source={Icons.sell}
        />
        <Label size={14} marginLeft={-7} color={Ecolors.whiteColor}>
          {`createordermodal.ban`}
        </Label>
      </Button>
      <Button
        onPress={() => onTransfer()}
        flexDirection={"row"}
        alignItems={"center"}
        backgroundColor={Ecolors.mainColor}
        paddingLeft={6}
        justifyContent={"center"}
        paddingRight={10}
        borderRadius={7}
        paddingVertical={5}
        width={I18nState == "vi" ? 121 : 87}
      >
        <ImageView
          widthHeight={27}
          resizeMode={"contain"}
          source={Icons.transfer}
        />
        <Label size={14} color={Ecolors.whiteColor}>
          {`createordermodal.chuyendoi`}
        </Label>
      </Button>
    </Div>
  );
};

export default React.memo(Row_Button_Create_Order);
