import { Alert, Button, Div, ImageView, Label } from "components";
import { Icons } from "constants/Icons";
import { Ecolors } from "constants/themes";
import React, { useEffect, useState } from "react";
import { getProductList } from "reducer/asset";
import { navigate } from "services/navigation";
import { useAppSelector } from "store/hooks";
import { convertNumber, convertPercent, convertShortName } from "utils/utils";
import Tab_asset from "./tab_asset";

function Item(p: { data: any; isBorderBottom?: boolean }) {
  const {
    color,
    code,
    interestOrHole,
    programList,
    sumOfValueNavCurrent,
    id,
    shortName,
  } = p.data;
  const I18nState = useAppSelector((state) => state.languages.I18nState);
  const currentUser = useAppSelector<any>((state) => state.authen.currentUser);
  const fatca =
    currentUser.fatca?.fatca1 == false &&
    currentUser.fatca?.fatca2 == false &&
    currentUser.fatca?.fatca3 == false
      ? true
      : false;

  return (
    <>
      <Div
        borderColor={Ecolors.bordercolorBox}
        borderWidth={1}
        borderRadius={8}
        marginTop={15}
      >
        <Div paddingHorizontal={15} paddingTop={17}>
          <Div
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Div>
              <Div
                flex={1}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"flex-start"}
              >
                <Div
                  marginRight={8}
                  widthHeight={11}
                  borderRadius={11}
                  backgroundColor={color}
                />
                <Label fontWeight={"700"} multilanguage={false}>
                  {convertShortName(shortName)}
                </Label>
              </Div>
              {/* <Button
                onPress={() => {
                  navigate("PerformanceScreen", {
                    productId: id,
                    flowApp: "AssetsScreen",
                  });
                }}
                paddingTop={12}
              >
                <Label
                  size={14}
                  color={Ecolors.linkColor}
                >{`createordermodal.xemthongtinquy`}</Label>
              </Button> */}
            </Div>

            <Button
              backgroundColor={Ecolors.spaceColor}
              borderRadius={20}
              paddingVertical={5}
              paddingHorizontal={10}
              flexDirection={"row"}
              onPress={() => {
                navigate("ListAssetDetailsModal", {
                  data: p.data,
                });
              }}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Label
                size={12}
                marginRight={4}
                fontWeight={"700"}
              >{`assetscreen.chitiet`}</Label>
              <ImageView
                widthHeight={10}
                resizeMode={"contain"}
                source={Icons.details}
              />
            </Button>
          </Div>
          {/* hodl volumn */}
          <Div
            marginTop={10}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Div>
              <Label
                size={14}
                color={Ecolors.grayText}
              >{`assetscreen.giatrihientai`}</Label>

              <Label size={16} multilanguage={false}>
                {convertNumber(Math.round(sumOfValueNavCurrent))}
              </Label>
            </Div>

            <Div alignItems="flex-end">
              <Label
                size={14}
                color={Ecolors.grayText}
              >{`assetscreen.loilo`}</Label>
              <Label
                color={
                  interestOrHole < 0 ? Ecolors.redColor : Ecolors.greenColor
                }
                size={16}
                multilanguage={false}
              >
                {` (${interestOrHole < 0 ? "" : "+"}${convertPercent(
                  interestOrHole
                )})`}
              </Label>
            </Div>
          </Div>
        </Div>
        {!!programList.length && (
          <Div paddingLeft={15} paddingRight={15}>
            <Label
              marginTop={11}
              size={14}
              color={Ecolors.grayText}
            >{`assetscreen.chuongtrinh`}</Label>
            {programList.map((i: any, index: number) => {
              return (
                <Button
                  key={i.id}
                  onPress={() => {
                    navigate("ProgramDetailsModal", {
                      data: i,
                    });
                  }}
                  paddingTop={5}
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Label
                    size={14}
                    color={Ecolors.linkColor}
                    multilanguage={false}
                  >
                    {I18nState == "vi" ? i.name : i.nameEn}
                  </Label>
                  <Label size={14} multilanguage={false}>{`${convertNumber(
                    Math.round(i.sumOfValueNavCurrent)
                  )}`}</Label>
                </Button>
              );
            })}
          </Div>
        )}
        <Div
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          marginTop={15}
          paddingHorizontal={15}
        >
          <Button
            marginRight={10}
            onPress={() => {
              navigate("CreateOrderModal", {
                orderType: "BUY",
                initData: {
                  product: p.data,
                },
              });
            }}
          >
            <Div
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              backgroundColor={Ecolors.mainColor}
              borderRadius={7}
              paddingVertical={5}
              width={I18nState == "vi" ? 83 : 103}
            >
              <ImageView
                widthHeight={27}
                resizeMode={"contain"}
                source={Icons.buy}
              />
              <Label size={14} color={Ecolors.whiteColor}>
                {`createordermodal.mua`}
              </Label>
            </Div>
          </Button>
          <Button
            marginRight={10}
            onPress={() => {
              if (!currentUser?.investmentProfile?.isReceivedHardProfile) {
                const content =
                  I18nState == "vi"
                    ? `Tài khoản của quý khách hiện tại chưa được duyệt hoặc chưa nhận được hồ sơ gốc/chưa ký hợp đồng điện tử. Nên không thể thực hiện lệnh bán/ chuyển đổi.`
                    : `You cannot create redemption/switching transaction due to pending account approval or not received hardcopy Open Account Contract/ unsigned e-Contract`;
                if (
                  currentUser?.investmentProfile?.status?.code !=
                    `INVESTMENT_PROFILE_APPROVE` ||
                  (currentUser?.investmentProfile?.status?.code ==
                    "INVESTMENT_PROFILE_APPROVE" &&
                    !currentUser?.investmentProfile?.isReceivedHardProfile)
                ) {
                  if (fatca == true) {
                    Alert.show({
                      content: content,
                      multilanguage: false,
                      type: 2,
                      titleClose: "alert.dongy",
                      onCancel: () => {},
                      onConfirm: () => {
                        navigate("DigitalSignatureScreen");
                      },
                    });
                  } else {
                    Alert.showError({
                      content: content,
                      multilanguage: false,
                    });
                  }
                  return;
                }
              }
              navigate("CreateOrderModal", {
                orderType: "SELL",
                initData: {
                  product: p.data,
                },
              });
            }}
          >
            <Div
              flexDirection={"row"}
              alignItems={"center"}
              backgroundColor={Ecolors.mainColor}
              paddingLeft={0}
              paddingRight={10}
              justifyContent={"center"}
              borderRadius={7}
              paddingVertical={5}
              width={I18nState == "vi" ? 84 : 95}
            >
              <ImageView
                marginHorizontal={9}
                widthHeight={27}
                resizeMode={"contain"}
                source={Icons.sell}
              />
              <Label size={14} marginLeft={-7} color={Ecolors.whiteColor}>
                {`createordermodal.ban`}
              </Label>
            </Div>
          </Button>
          <Button
            onPress={() => {
              if (!currentUser?.investmentProfile?.isReceivedHardProfile) {
                const content =
                  I18nState == "vi"
                    ? `Tài khoản của quý khách hiện tại chưa được duyệt hoặc chưa nhận được hồ sơ gốc/chưa ký hợp đồng điện tử. Nên không thể thực hiện lệnh bán/chuyển đổi.`
                    : `You cannot create redemption/switching transaction due to pending account approval or not received hardcopy Open Account Contract/ unsigned e-Contract`;
                if (
                  currentUser?.investmentProfile?.status?.code !=
                    `INVESTMENT_PROFILE_APPROVE` ||
                  (currentUser?.investmentProfile?.status?.code ==
                    "INVESTMENT_PROFILE_APPROVE" &&
                    !currentUser?.investmentProfile?.isReceivedHardProfile)
                ) {
                  if (fatca == true) {
                    Alert.show({
                      content: content,
                      multilanguage: false,
                      type: 2,
                      titleClose: "alert.dongy",
                      onCancel: () => {},
                      onConfirm: () => {
                        navigate("DigitalSignatureScreen");
                      },
                    });
                  } else {
                    Alert.showError({
                      content: content,
                      multilanguage: false,
                    });
                  }
                  return;
                }
              } else {
                navigate("CreateOrderModal", {
                  orderType: "TRANSFER",
                  initData: {
                    product: p.data,
                  },
                });
              }
            }}
          >
            <Div
              flexDirection={"row"}
              alignItems={"center"}
              backgroundColor={Ecolors.mainColor}
              paddingLeft={6}
              justifyContent={"center"}
              paddingRight={10}
              borderRadius={7}
              paddingVertical={5}
              width={I18nState == "vi" ? 121 : 87}
            >
              <ImageView
                widthHeight={27}
                resizeMode={"contain"}
                source={Icons.transfer}
              />
              <Label size={14} color={Ecolors.whiteColor}>
                {`createordermodal.chuyendoi`}
              </Label>
            </Div>
          </Button>
        </Div>

        <Div height={15} />
      </Div>
    </>
  );
}

function PercentAsset() {
  // const productList = useState<Array<any>>([]);
  const productList = useAppSelector((state) => getProductList(state));
  const [asset, SetAsset] = useState<Array<any>>([]);

  useEffect(() => {
    let aa = productList?.map((item, index) => {
      return {
        id: item?.id,
        color: item?.color,
        name: item?.name,
        code: item?.code,
        holdingVolume: item?.holdingVolume,
        sumOfValueNavCurrent: item?.sumOfValueNavCurrent,
        interestOrHole: item?.interestOrHole,
        ratePercent: item?.ratePercent,
        navCurrent: item?.navCurrent,
        navInvested: item?.navInvested,
        sumOfValueNavInvested: item?.sumOfValueNavInvested,
        programList: item?.programList,
        nameEn: item?.nameEn,
        shortName: item?.shortName,
      };
    });
    SetAsset(aa.sort((a, b) => b.ratePercent - a.ratePercent));
    return () => {};
  }, [productList]);

  if (asset?.length == 0) {
    return (
      <Div
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        paddingHorizontal={15}
      >
        <Button
          paddingTop={15}
          flexDirection="row"
          alignItems="center"
          onPress={() => {
            navigate("PendingOrderScreen");
          }}
        >
          <Label
            fontWeight="bold"
            color={Ecolors.linkColor}
          >{`overviewscreen.xemlenhchokhop`}</Label>
          <ImageView
            source={Icons.forward}
            marginTop={3}
            widthHeight={12}
            tintColor={Ecolors.linkColor}
            marginLeft={5}
            resizeMode="contain"
          />
        </Button>
      </Div>
    );
  }
  return (
    <Div>
      <Div
        flexDirection={"row"}
        alignItems={"center"}
        borderRadius={5}
        justifyContent={"space-between"}
        overflow={"hidden"}
        marginHorizontal={16}
        marginTop={11}
      >
        {asset?.map((item: any, index: number) => {
          const percent = item.ratePercent;
          return (
            <Div
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              key={index}
              width={`${percent}%`}
              height={26}
              backgroundColor={item.color}
            ></Div>
          );
        })}
      </Div>
      <Div
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        paddingHorizontal={15}
      >
        <Button
          paddingTop={15}
          flexDirection="row"
          alignItems="center"
          onPress={() => {
            navigate("PendingOrderScreen");
          }}
        >
          <Label
            fontWeight="bold"
            color={Ecolors.linkColor}
          >{`overviewscreen.xemlenhchokhop`}</Label>
          <ImageView
            source={Icons.forward}
            marginTop={3}
            widthHeight={12}
            tintColor={Ecolors.linkColor}
            marginLeft={5}
            resizeMode="contain"
          />
        </Button>
      </Div>
      <Tab_asset />

      {/* <Div
        flexDirection={"column"}
        marginHorizontal={16}
        marginTop={10}
        minHeight={200}
        style={EStyle.shadowItem}
        backgroundColor={Ecolors.whiteColor}
      >
        {asset?.map((item: any, index: number) => {
          return (
            <Item
              key={item.id}
              data={item}
              isBorderBottom={index < asset?.length - 1}
            />
          );
        })}
      </Div> */}
    </Div>
  );
}
export default React.memo(PercentAsset);
