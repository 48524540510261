import { Div, Label } from "components";
import { Ecolors } from "constants/themes";
import React from "react";

const Table_Units = (p: {
  left: {
    title: string;
    amount: number;
  };
  right: {
    title: string;
    amount: number;
  };
}) => {
  return (
    <Div
      width={307}
      borderWidth={1}
      borderColor={Ecolors.bordercolorBox}
      borderRadius={8}
      marginTop={9}
      overflow="hidden"
    >
      <Div
        backgroundColor={"#F9F9FA"}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Div
          paddingVertical={5}
          flex={1}
          alignItems="center"
          justifyContent="center"
          borderRightWidth={0.5}
          borderRightColor={Ecolors.bordercolorBox}
        >
          <Label size={14} textAlign="center">
            {p.left.title}
          </Label>
        </Div>
        <Div
          paddingVertical={5}
          flex={1}
          alignItems="center"
          justifyContent="center"
          borderLeftWidth={0.5}
          borderLeftColor={Ecolors.bordercolorBox}
        >
          <Label size={14} textAlign="center">
            {p.right.title}
          </Label>
        </Div>
      </Div>
      <Div width={"100%"} height={1} backgroundColor={Ecolors.bordercolorBox} />
      <Div
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Div
          paddingVertical={5}
          minHeight={41}
          flex={1}
          alignItems="center"
          justifyContent="center"
          borderRightWidth={0.5}
          borderRightColor={Ecolors.bordercolorBox}
        >
          <Label size={14} multilanguage={false} textAlign="center">
            {p.left.amount}
          </Label>
        </Div>
        <Div
          paddingVertical={5}
          flex={1}
          minHeight={41}
          alignItems="center"
          justifyContent="center"
          borderLeftWidth={0.5}
          borderLeftColor={Ecolors.bordercolorBox}
        >
          <Label size={14} multilanguage={false} textAlign="center">
            {p.right.amount}
          </Label>
        </Div>
      </Div>
    </Div>
  );
};

export default React.memo(Table_Units);
