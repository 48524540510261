import { Div, Label } from "components";
import { Ecolors } from "constants/themes";
import React from "react";

const Row_Space = (
  p: {
    left: {
      title: string;
      color?: any;
      multilanguage?: boolean;
      size?: number;
    };
    right: {
      title: string;
      color?: any;
      multilanguage?: boolean;
      size?: number;
    };
    marginTop?: number;
  },
  ...children
) => {
  return (
    <Div
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      marginTop={p.marginTop || 8}
    >
      <Div
        flex={1}
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Label size={14} color={Ecolors.grayText} {...p.left}>
          {p.left.title}
        </Label>
      </Div>
      <Label size={14} color={Ecolors.grayText} {...p.right}>
        {p.right.title}
      </Label>
    </Div>
  );
};

export default React.memo(Row_Space);
